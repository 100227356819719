// reusable snippets of CSS

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin inline-flex-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin trim-v-margins {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// font awesome icon selectors
$icon: "i.fas, i.far, i.fab, i.fal, i.fad, svg";
