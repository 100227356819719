@import "palettes";
@import "theme";
@import "mixins";

hr {
  margin: 40px 0;
  background: $gray-300;
  border: none;
  height: 1px;
}
