// colors for links, tags, etc.
// see colors.scss for available colors
$accent: $indigo-500;
$light-accent: $indigo-200;
$dark-accent: $indigo-900;

// font types
// also see /_includes/fonts.html to change fonts
$serif: "Lora", serif; // font name, fallback font type
$sans: "Poppins", sans-serif;
$mono: "Cousine", monospace;

// line spacing
$spacing: 1.2;

// transitions
$fast: 0.25s ease;
$slow: 0.5s ease;

// effects
$shadow: 0 0 8px 0 #00000030;

// screen widths
// used by various components
$page: 800px; // max-width of the main page column
$tablet: 800px; // screen width cutoff point for tablets
$phone: 500px; // screen width cutoff point for phones
